import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { TabMenuItem } from './TabMenuItem/TabMenuItem';
import styles from './TabMenu.module.scss';

export type TabMenuItemValue = string;

type TabMenuProps = ComponentProps<'div'> & {
	items: Array<{
		value: TabMenuItemValue;
		label: string;
		href?: string;
	}>;
	selectedItemValue: TabMenuItemValue;
	onItemClick: (value: TabMenuItemValue) => void;
};
export const TabMenu: React.VFC<TabMenuProps> = ({ items, selectedItemValue: selectedItemId, className, onItemClick, ...restProps }) => {
	return (
		<div className={classNames(styles.container, className)} {...restProps}>
			{items.map((item) => (
				<TabMenuItem key={item.value} selected={selectedItemId === item.value} href={item.href} onClick={() => onItemClick(item.value)}>
					{item.label}
				</TabMenuItem>
			))}
		</div>
	);
};
