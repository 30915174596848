import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useImpersonateId = (): string | null => {
	const router = useRouter();
	const impersonateId = router.query.impersonateId as string;

	useEffect(() => {
		if (impersonateId) {
			document.body.classList.add('is-impersonated');
		} else {
			document.body.classList.remove('is-impersonated');
		}

		return () => {
			document.body.classList.remove('is-impersonated');
		};
	}, [impersonateId]);

	return impersonateId;
};
