import classNames from 'classnames';
import styles from './TabMenuItem.module.scss';

type TabMenuItemProps = {
	selected: boolean;
	onClick: () => void;
	href?: string;
	className?: string;
};

export const TabMenuItem: React.FC<TabMenuItemProps> = ({ selected, onClick, className, href, children, ...restProps }) => {
	return (
		<>
			<button
				className={classNames(
					styles.button,
					{
						[styles.button___selected]: selected,
					},
					className,
				)}
				onClick={onClick}
				{...restProps}
			>
				<div className={styles.contentWrapper}>{children}</div>
			</button>
			<a
				tabIndex={-1}
				href={href}
				className={classNames(styles.button___links, className)}
				title={typeof children === 'string' ? children : undefined}
				{...restProps}
			>
				<span>{children}</span>
			</a>
		</>
	);
};
