import { AudiencesResult, getAudiencesAdapter } from 'application/adapters/CDP/CDPAdapter';

export type Audiences = {
	audiences: {
		name: string;
		id: string;
	}[];
};

export const getAudiences = async (hostname: string, memberId: string, impersonateId?: string): Promise<AudiencesResult> => {
	const audiences = await getAudiencesAdapter(hostname, memberId, impersonateId);
	return audiences;
};
